import React from 'react'
import { Form } from 'react-bootstrap'
import { useFormState, useFormContext } from 'react-hook-form'
import { css } from '@emotion/react'

const TextAdjust = css`
  position: relative;
  top: -12px;
`

const FileUpload = ({ name }) => {
  const { control, setValue: setFieldValue, register } = useFormContext()
  const { errors } = useFormState({ control })
  const nameContent = `${name}_content`
  const nameContentFilename = `${name}_content_filename`
  const error = errors[nameContent]

  const getBase64 = file => {
    return new Promise(resolve => {
      let baseURL = ''
      let reader = new FileReader()
      reader.readAsDataURL(file)

      reader.onload = () => {
        baseURL = reader.result
        resolve(baseURL)
      }
    })
  }
  const fileInput = register(name)

  const handleChange = e => {
    fileInput.onChange(e)
    const fileObj = e.target.files?.[0]
    if (!fileObj) {
      return
    }
    getBase64(fileObj).then(value =>
      setFieldValue(nameContent, value.split(',')[1])
    )
    setFieldValue(nameContentFilename, fileObj.name)
  }

  return (
    <>
      <input type="hidden" {...register(nameContent)} />
      <input type="hidden" {...register(nameContentFilename)} />
      <Form.Control
        type="file"
        id={name}
        name={name}
        accept=".doc,.docx,.pdf,.rtf,.txt"
        isInvalid={!!error}
        {...fileInput}
        onChange={handleChange}
      />
      <Form.Text css={TextAdjust}>
        File can be .doc(x), .pdf, .rtf, or .txt.
      </Form.Text>
      {!!error && (
        <Form.Control.Feedback type="invalid" className="mb-3">
          {error.message}
        </Form.Control.Feedback>
      )}
    </>
  )
}

export default FileUpload
