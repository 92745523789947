import React from 'react'
import { Form } from 'react-bootstrap'
import { useFormState, useFormContext } from 'react-hook-form'

const SelectOptions = ({ name, values, required, labelShort }) => {
  const { control, register } = useFormContext()
  const { errors } = useFormState({ control })
  const error = errors[name]
  return (
    <>
      <Form.Select
        id={name}
        name={name}
        required={required}
        isInvalid={!!error}
        className="form-control"
        {...register(name)}
      >
        <option value="">Choose {labelShort && labelShort}</option>
        {values.map((value, index) => (
          <option
            key={`fieldSelectOpts-${index.toString()}`}
            value={value.value}
          >
            {value.label}
          </option>
        ))}
      </Form.Select>
      {!!error && (
        <Form.Control.Feedback type="invalid" className="mb-3">
          {error.message}
        </Form.Control.Feedback>
      )}
    </>
  )
}

export default SelectOptions
