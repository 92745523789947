import React from 'react'
import { Form } from 'react-bootstrap'
import { useFormState, useFormContext } from 'react-hook-form'

const InputText = ({ name, required, ...props }) => {
  const { control, register } = useFormContext()
  const { errors } = useFormState({ control })
  const error = errors[name]
  return (
    <>
      <Form.Control
        type="text"
        id={name}
        name={name}
        required={required}
        isInvalid={!!error}
        {...register(name)}
        {...props}
      />
      {!!error && (
        <Form.Control.Feedback type="invalid" className="mb-3">
          {error.message}
        </Form.Control.Feedback>
      )}
    </>
  )
}

export default InputText
