import React from 'react'
import { Form } from 'react-bootstrap'
import { useFormState, useFormContext } from 'react-hook-form'

export const otherOption = { label: 'Other', value: 'other' }
export const otherLabel = 'If "Other" please explain here'

const SelectCheckboxes = ({
  name,
  values,
  required,
  placeholder,
  inlineLabel,
}) => {
  const { register, control, values: formValues } = useFormContext()
  const { errors } = useFormState({ control })
  const error = errors[name]
  return (
    <>
      {inlineLabel && placeholder && <Form.Label>{placeholder}</Form.Label>}
      {values.map((value, index) => (
        <Form.Check
          key={`fieldSelectCheck-${index.toString()}`}
          type="checkbox"
          label={value.label}
          name={name}
          value={value.value}
          required={required}
          isInvalid={!!error}
          {...register(name)}
        />
      ))}
      {formValues?.[name] === otherOption.value && (
        <Form.Control type="text" label={otherLabel} />
      )}
      {!!error && (
        <Form.Control.Feedback type="invalid" className="mb-3">
          {error.message}
        </Form.Control.Feedback>
      )}
    </>
  )
}

export default SelectCheckboxes
